import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.min.css";

import Router from "./router";
import i18n from "./translation";
import { useEffect } from "react";

const App = () =>{
  useEffect(() => {
    const disablePinchZoom = (e: any) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])
 return(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
)};

ReactDOM.render(<App />, document.getElementById("root"));
